import { EditorSDK } from '@wix/platform-editor-sdk';
import { EcomComponent } from '@wix/ecom-platform-sdk';
import { removeBookCheckoutPageOnEcom } from '../pages-panel-actions';
import { addBookingCheckoutPage } from '../pages-actions';
import {
  ecomRolloutButNotSite,
  getBookingsData,
  isEcomCartInstalled,
  isEcomCheckoutInstalled
} from '../editor-sdk-actions';
import { EditorScriptApi } from '../../api/api';
import { isExperimentEnabled } from '../experiments';
import { experiments } from '../../constants';
import { updateIsCartEnabledBusinessProperty, getIsCartEnabledBusinessProperty } from '../migrate-actions';
import { openCartSuccessfullyInstalledModal, openEcomMigrationProposalModal } from '../migration-modals';
import { MigrateAction } from '../../migration';
import { Translations } from '../translations';

interface SkipEcomInstallationParams {
  isFirstInstall: boolean;
  _editorSDK: EditorSDK;
  editorScriptApi: EditorScriptApi;
  appToken?: string;
  locale?: any;
  editorTranslation: Translations;
  migrationActionInput: MigrateAction;
}

interface RequiredEcomComponentsParams {
  isFirstInstall: boolean;
  _editorSDK: EditorSDK;
  editorScriptApi: EditorScriptApi;
}

export const markAsEcom = async ({
  isEcomReady,
  editorScriptApi,
  editorSdk,
  appToken,
}): Promise<boolean> => {
  if (!isEcomReady) {
    const isReady = await editorScriptApi.setEcomCompatible();
    if (isReady) {
      const isEcomMigrationEnabled = await isExperimentEnabled(experiments.IS_ECOM_MIGRATION_ENABLED);

      if (!isEcomMigrationEnabled) {
        await removeBookCheckoutPageOnEcom(editorSdk, appToken, editorScriptApi);
      }
    } else {
      await addBookingCheckoutPage(editorSdk, appToken);
    }
    return isReady;
  }
};

export const skipEcomInstallationAction = (
  {
    isFirstInstall,
    _editorSDK,
    editorScriptApi,
    appToken,
    locale,
    editorTranslation,
    migrationActionInput
  }: SkipEcomInstallationParams
) => {
  return async () => {
    const isCartEnabledFT = await isExperimentEnabled(experiments.CART_ENABLED);
    const isEcomMigrationEnabled = await isExperimentEnabled(experiments.IS_ECOM_MIGRATION_ENABLED);
    const isEcomMigrationProposalModal = await isExperimentEnabled(experiments.ECOM_MIGRATION_MODAL_ENABLED);

    if (isEcomMigrationEnabled) {
      return true;
    }

    if (!isFirstInstall) {
      const isEcomRolloutButNotSite = await ecomRolloutButNotSite(
        _editorSDK,
        editorScriptApi,
      );

      if (isEcomRolloutButNotSite) {
        return false;
      }

      const isBookingRollout = await editorScriptApi.getIsBookingsOnEcom();
      const ecomCheckoutInstalled = await isEcomCheckoutInstalled(_editorSDK)

      if ((!isBookingRollout || !ecomCheckoutInstalled) && isEcomMigrationProposalModal) {
        const { instance } = await getBookingsData(_editorSDK, appToken);
        openEcomMigrationProposalModal(
          _editorSDK,
          editorScriptApi,
          locale,
          instance,
          editorTranslation,
          migrationActionInput
        );
        return true;
      }
    }

    if (isCartEnabledFT) {
      const { instance } = await getBookingsData(_editorSDK, appToken);
      const isCartEnabled: boolean | undefined = await getIsCartEnabledBusinessProperty(editorScriptApi, instance);

      if (isFirstInstall) {
        // undefined meaning this flag never initiate and we should initiate for first install to true
        if (isCartEnabled === undefined) {
          await updateIsCartEnabledBusinessProperty(editorScriptApi, instance, true);
        }
        return false;
      }

      if (!isCartEnabled) {
        return true;
      }

      const isCartinstalled = !!(await isEcomCartInstalled(_editorSDK));

      if (!isCartinstalled) {
        await openCartSuccessfullyInstalledModal(_editorSDK, editorScriptApi, locale, instance);
      }

      return isCartinstalled;
    }

    return !(await editorScriptApi.canInstallEcom());
  }
}

export const requiredEcomComponentsAction = (
  {
    isFirstInstall,
    _editorSDK,
    editorScriptApi
  }: RequiredEcomComponentsParams
): () => Promise<EcomComponent[]> => {
  return async () => {
    const isCartEnabled = await isExperimentEnabled(experiments.CART_ENABLED);
    const isBookingRollout = await editorScriptApi.getIsBookingsOnEcom();

    if (!isFirstInstall) {
      const isEcomRolloutButNotSite = await ecomRolloutButNotSite(_editorSDK, editorScriptApi,);

      if (isEcomRolloutButNotSite) {
        return [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE]
      }
    }

    if (isCartEnabled && (isFirstInstall || isBookingRollout)) {
      return [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE, EcomComponent.CART, EcomComponent.CART_ICON];
    }
    return [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE];
  };
}

